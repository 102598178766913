import { Stack, Text } from "@chakra-ui/react";

import { AuthenticatedMainLayout } from "../AuthenticatedMainLayout";

export const NotFoundScreen = () => {
  return (
    <AuthenticatedMainLayout>
      <Stack textAlign="center" spacing={4}>
        <Text
          textStyle="Heading/Large"
          fontWeight="bold"
          fontSize="58px"
          color="semantic.contents.tertiary"
          as="h2"
        >
          404
        </Text>
        <Text textStyle="Paragraph/Large" color="semantic.contents.tertiary">
          ページが見つかりませんでした。
        </Text>
      </Stack>
    </AuthenticatedMainLayout>
  );
};
